import React from "react";

import Page from "lib/@cms/components/layout/Page";
import PageHeader from "lib/@cms/components/cms/PageHeader";
import ContentBox from "lib/@cms/components/shared/ContentBox";
import Button from "lib/@cms/components/primitive/Button";
import Divider from "lib/@cms/components/primitive/Divider";

function ToyLoansPage() {
  return (
    <Page pathname="toy-loans">
      {data => {
        return (
          <React.Fragment>
            <PageHeader
              variant={PageHeader.variant.GRADIENTS}
              color={PageHeader.color.PRIMARY}
              data={data.PageHeaderGradients}
            />

            <div className="tw-container">
              <ContentBox title={data.TextBodyA.title}>
                {data.TextBodyA.description}
                <ul className="tw-my-3">
                  {data.TextBodyA.textBody?.map((element, index) => {
                    return <li key={`TextBodyList-${index}`}>{element.item}</li>;
                  })}
                </ul>
                <Button is="a" href={data.TextBodyA.button.href} target="_blank">
                  {data.TextBodyA.button.title}
                </Button>
              </ContentBox>
              <Divider className="tw-my-6" />

              <ContentBox title={data.TextBodyB.title}>
                <ul className="tw-list-disc tw-ml-6 tw-my-3">
                  {data.TextBodyB.list?.map((element, index) => {
                    return <li key={`TextBodyList-${index}`}>{element.item}</li>;
                  })}
                </ul>
              </ContentBox>
              <Divider className="tw-my-6" />

              <ContentBox title={data.TextBodyC.title}>
                {data.TextBodyC.description}
                <ul className="tw-ml-6 tw-my-3">
                  {data.TextBodyC.textBody?.map((element, index) => {
                    return <li key={`TextBodyList-${index}`}>{element.item}</li>;
                  })}
                </ul>
                <Button is="a" href={data.TextBodyC.button.href} target="_blank">
                  {data.TextBodyC.button.title}
                </Button>
              </ContentBox>
              <Divider className="tw-my-16 sm:tw-my-24" />
            </div>
          </React.Fragment>
        );
      }}
    </Page>
  );
}

export default ToyLoansPage;
